<div class="about-section">
    <div class="container-fluid p-0">
        <div class="row no-gutters align-items-center">
            <div class="col-lg-5">
                <div class="about-img first-bg">
                    <div class="about-big-img">
                        <img src="../../assets/images/home-img/about-left.png" alt="">
                    </div>
                    <div class="about-mini-img">
                        <img src="../../assets/images/home-img/min-img.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="about-content">
                    <div class="section-header">
                        <h5>Who We Are</h5>
                        <h3><span>About Us</span></h3>
                    </div>
                    <p>Provoltus Energy Services has been a pioneer in delivering turnkey solutions for Renewable Energy Projects Land Acquisition, Consultation,  Bid Process Management and Solar Product Distribution. A dynamic team of experts and experienced professionals, Provoltus enables its clients to take a step towards Carbon Positivity by harnessing Earth's Renewable Resources for maximum energy extraction. Provoltus was found in 2016 and now is one of the fastest-growing Project Development company with projects of more than 500 MWp under pipeline. We work with IPP’s, Commercial, Industrial and Residential customers to make a move towards a Greener, Sustainable Future.</p>
                </div>
            </div>
        </div>
        <div class="row no-gutters align-items-center">
            <div class="col-lg-5 order-lg-2">
                <div class="about-img second-bg">
                    <div class="row no-gutters">
                        <div class="col-5">
                            <div class="sec-mini-images">
                                <img class="img-1" src="../../assets/images/superior-product/2.png" alt="">
                                <img class="img-2" src="../../assets/images/superior-product/3.png" alt="">
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="sec-img">
                                <img src="../../assets/images/superior-product/1.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 order-lg-1">
                <div class="about-content about-content-box">
                    <h4><span class="icon"><img src="../../assets/images/about-page-icon/1.svg" alt=""></span><span class="txt">Best-in-Class Product Range</span></h4>
                    <p>Provoltus partners with top global brands to make sure only the best products are installed for the projects we take up. We offer a product range that is technologically advance, more cost-effective, and reliable. From Photovoltaic modules to Inverters, our product range includes a wide variety of solutions for turnkey Solar Project development.</p>
                </div>
            </div>
        </div>
        <div class="row no-gutters align-items-center">
            <div class="col-lg-5">
                <div class="about-img third-bg">
                    <div class="main-img">
                        <img src="../../assets/images/eco-friendly/1.png" alt="">
                    </div>
                    <div class="eco-side-img">
                        <img src="../../assets/images/eco-friendly/2.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="about-content about-content-box-right">
                    <h4><span class="icon"><img src="../../assets/images/about-page-icon/2.svg" alt=""></span><span class="txt">Industry Expert Consultation</span></h4>
                    <p>Provoltus brings to you the essential industry insights and expertise from experienced professionals. We are committed to quality and service excellence in all that we do, bringing our best insights to clients in terms of product assembling, project design, and deploying modern photovoltaic systems to generate electricity at minimal costs.</p>
                </div>
            </div>
        </div>
        <div class="row no-gutters align-items-center">
            <div class="col-lg-5 order-lg-2">
                <div class="about-img fourth-bg">
                    <div class="team-main-img">
                        <img src="../../assets/images/team-img/1.png" alt="">
                    </div>
                    <div class="team-side-img">
                        <img src="../../assets/images/team-img/2.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-7 order-lg-1">
                <div class="about-content about-content-box">
                    <h4><span class="icon"><img src="../../assets/images/about-page-icon/3.svg" alt=""></span><span class="txt">End to End Expertise</span></h4>
                    <p>From requirement mapping and infrastructure assessment to deployment and go-live, we cater both Commercial and Residential clients with complete end-to-end solutions, thereby making sure every aspect of successful project development is accounted for. We leave no stone unturned to make sure we leave a positive impact on the environment and society. </p>
                    <button class="btn btn-md" routerLink="/contact"><span>Get In Touch</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="section-pad team-section">
    <div class="container">
        <div class="section-header text-center">
            <h5>Who We Are</h5>
            <h3><span>Our Amazing Team</span></h3>
            <p>We are problem solvers and go-getters with the eagerness to make <br> this World greener and more sustainable. </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="team-img">
                    <img src="../../assets/images/team-img.png" alt="">
                    <div class="team-details">
                        <h4>Jack Sparrow</h4>
                        <p>Chief Executing Officer</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="team-img">
                    <img src="../../assets/images/team-img-02.png" alt="">
                    <div class="team-details">
                        <h4>Jack Sparrow</h4>
                        <p>Chief Executing Officer</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="team-img">
                    <img src="../../assets/images/team-img-02.png" alt="">
                    <div class="team-details">
                        <h4>Jack Sparrow</h4>
                        <p>Chief Executing Officer</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="team-img mb-lg-0">
                    <img src="../../assets/images/team-img.png" alt="">
                    <div class="team-details">
                        <h4>Jack Sparrow</h4>
                        <p>Chief Executing Officer</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="team-img mb-lg-0">
                    <img src="../../assets/images/team-img-02.png" alt="">
                    <div class="team-details">
                        <h4>Jack Sparrow</h4>
                        <p>Chief Executing Officer</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="team-img mb-0">
                    <img src="../../assets/images/team-img-02.png" alt="">
                    <div class="team-details">
                        <h4>Jack Sparrow</h4>
                        <p>Chief Executing Officer</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

import { Injectable } from '@angular/core';
import { LightboxComponent } from './lightbox/lightbox.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { baseUrl, categories } from './config';
import { SuccessComponent } from './success/success.component';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // successModalShow() {
  //   throw new Error("Method not implemented.");
  // }
  constructor(private httpclient:HttpClient, private modalService: NgbModal){}

 
  public Get(api:any) {
    return new Promise((resolve, reject) => {
      this.httpclient.get(baseUrl + api)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  public Post(api: any,formData:any) {

    return new Promise((resolve, reject) => {
      this.httpclient.post(baseUrl + api,formData)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  advertiserModalShow(a=null,b=null,c=null) {
    if(b){
    const activeModal = this.modalService.open(LightboxComponent, { windowClass: 'myCustomModalClass2',size: 'lg' });
    activeModal.componentInstance.index = a;
    activeModal.componentInstance.aray = b;
    activeModal.componentInstance.imgUrl=c}
  }
  successModalShow(){
    const activeModal = this.modalService.open(SuccessComponent, { windowClass: 'succesModal',size: 'lg' });
  }
}

<div class="project-section section-pad">
    <div class="container">
        <div class="section-header">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <h5>Projects</h5>
                    <h3><span>Key Projects</span></h3>
                </div>
                <div class="col-lg-7">
                    <p>We have a portfolio of successful projects which includes both Utility Scale and Rooftop projects across the country for Industrial, Commercial & Residential sectors.</p>
                </div>
            </div>
        </div>
        <div class="row" *ngFor="let gal of rows; let i = index;">
            <div  [ngClass]="{'col-lg-5': i%2 == 0, 'col-lg-7' : i%2 != 0 }">
                <div class="project-img" (click)='open(i*2, gallery,imgUrl)'>
                    <img  src="{{imgUrl+gallery[i*2].file.file_path+gallery[i*2].file.file_name}}" alt="">
                    <h4>{{gallery[i*2].heading}}</h4>
                </div>
            </div>
            <div *ngIf="gallery.length > i*2+1"   [ngClass]="{'col-lg-5': i%2 != 0, 'col-lg-7' : i%2 == 0 }">
                <div class="project-img"  (click)='open(i*2+1, gallery,imgUrl)'>
                    <img src="{{imgUrl+gallery[i*2+1].file.file_path+gallery[i*2+1].file.file_name}}" alt="">
                    <h4>{{gallery[i*2+1].heading}}</h4>
                </div>
            </div>
        </div>
    </div>
</div>

<header [class.header_scroll]="header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-3">
                <div class="header-logo">
                    <a routerLink="/">
                        <img src="../../assets/images/logo.svg" alt="">
                    </a>
                </div>
            </div>
            <div class="col-lg-10 col-9 mobileNav" [ngClass]="mobileNav ? 'hide' : 'open'" >
                <button class="menu-close-btn btn d-lg-none" (click)="mobileNavtrigger()"><i class="bx bx-x"></i></button>
                <div class="navbar">
                    <ul>
                        <li class="nav-item" (click)="mobileNavtrigger()"><a class="nav-link" routerLink="/about">About</a></li>
                        <li class="nav-item" (click)="mobileNavtrigger()"><a class="nav-link" routerLink="/services">Services</a></li>
                        <li class="nav-item" (click)="mobileNavtrigger()"><a class="nav-link" routerLink="/key-projects">Key Projects</a></li>
                        <li class="nav-item drop-down"><a class="nav-link" href="javascript:void(0)" (click)="navigateToPartner(1, 'partner')">Partner Brands</a>
                            <div class="dropdown-menu">
                                <ul>
                                    <li (click)="mobileNavtrigger()" *ngFor="let cat of category; let i = index"><a href="javascript:void(0)" (click)="navigateToPartner(cat.id, 'brands')">{{cat.name}}</a>
                                        <div class="dropdown-menu">
                                            <ul>
                                                <li (click)="mobileNavtrigger()" *ngFor="let subcat of cat.childrens"><a href="javascript:void(0)" (click)="navigateToPartner(cat.id, 'products')">{{subcat.name}}</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="nav-item contact-btn" routerLink="/contact" (click)="mobileNavtrigger()">Contact Us</li>
                    </ul>
                </div>
            </div>
            <div class="col-9 d-lg-none">
                <div class="menu-trigger">
                    <span (click)="mobileNavtrigger()"><i class="bx bx-menu"></i></span>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="header-padding"></div>
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, NavigationStart , Event, NavigationEnd} from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Products,productEnquiry, categoryDetails, Sponsers} from '../config';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  closeResult = '';
  validations_form: FormGroup;
  presentToast: any;
  filterProducts:any=[];
  sponsers:any;
  validation_messages = {
    name: [
      {
        type: 'required',
        message: 'Name is a required field.',
      },
    ],
    contact_number: [
      {
        type: 'required',
        message: 'Mobile number is required.',
      },
      {
        type: 'minLength',
        message: 'Invalid mobile number.',
      },
      {
        type: 'maxLength',
        message: 'Invalid mobile number.',
      }
    ],
    email: [
      {
        type: 'required',
        message: 'Email is a required field.',
      },
    ],
    city: [
      {
        type: 'required',
        message: 'city is a required field.',
      },
    ],
    organisation: [
      {
        type: 'required',
        message: 'organisation is a required field.',
      },
    ],
    product_id: [
      {
        type: 'required',
        message: 'product is a required field.',
      },
    ],
    requirement: [
      {
        type: 'required',
        message: 'requirement is a required field.',
      },
    ],
  };
  imgUrl: any;
  prod: any;
  selectedForm:any;
  id:any;
  filter_data: any;
  details: any;
  category: any;
  catdetails: any;
  type:any;
 
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder,private route:ActivatedRoute, private apiService:  ApiService, private vps: ViewportScroller, private router:Router) { 
    // let sessionData=sessionStorage.getItem("data");
    // let parsedData=(sessionData!=null)?JSON.parse(sessionData):null;
    // sessionStorage.removeItem("data");
    // console.log(parsedData);
    this.route.queryParams.subscribe(data=>{
      // console.log(params);
      // let parsedData=JSON.parse(params['data']);
      // console.log(parsedData);
      // this.category = data;
      // let cat = this.category;
      // // console.log(this.category)
      // console.log(JSON.parse(cat));
      console.log(data);
      this.id = data.id;
      this.type = data.type;
      this.filterProducts=[];
      this.getProducts();
      this.getDetails();
      // this.router.events.subscribe((routerEvent: Event) => {
      //   if (routerEvent instanceof NavigationEnd) {
      //     this.scroll(this.type);
      //     // console.log('route changed');
      //   }
      // });
      setTimeout(() => {
        this.scroll(this.type);
      }, 500);
      
    })
    this.validations_form = this.formBuilder.group({
      name: new FormControl('', Validators.compose([ 
        Validators.required,
      ])),
      contact_number: new FormControl('', Validators.compose([ 
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      city: new FormControl('', Validators.compose([ 
        Validators.required,
      ])),
      organisation: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      product_id: new FormControl('', Validators.compose([
        // Validators.required,
      ])),
      requirement: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
  }
  product(value :any){
    if (!this.validations_form.invalid) {
      this.validations_form.value.product_id=this.selectedForm.id;
      console.log(value);
      this.apiService.Post(productEnquiry,value).then(data=>{

      }).catch(data=>{

        console.log(Error);
  
      });
    }
    else{
      console.log("invalid condition");
    }
    
  }

  ngOnInit(): void {
    this.getDetails();
    this.getSponser();
  }
  getProducts()
  {
    this.apiService.Get(Products).then((response:any)=>{
      this.prod=response.data.data;
      // for(var i = 0; i <this.prod.length; i++){
      //   let result = this.prod.find((x: { category_id: any; }) => x.category_id==this.id);
      //   if(result){
      //     this.filterProducts.push(result)
      //   }
      // }
      console.log("Product List",this.prod)
      this.prod.forEach((element:any) => {
       if(element.category_id==this.id)
        {
          this.filterProducts.push(element);
          console.log("Filter Products",this.filterProducts);
        }
      });
      console.log(this.filterProducts);
      this.imgUrl=response['base_url'];
      if(this.filterProducts.length == 0){
        this.filterProducts = null;
      }
      
    }).catch(error=>{
      console.log(error);
    })
  }
  getDetails(){
    this.apiService.Get(categoryDetails+this.id).then((response:any)=>{
      this.catdetails=response.data;
      console.log(this.catdetails);
    }).catch(error=>{
      console.log(error);
    })
  }
  getSponser(){
    this.apiService.Get(Sponsers).then((response:any)=>{
      this.sponsers = response.data;
      console.log('Sponsers', response);
    }).catch(error=>{
      console.log(error);
    })
  }
  open(enquire: any,product:any) {
    console.log(name);
    this.selectedForm=product;
    this.modalService.open(enquire, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  success(){
    if(!this.validations_form.invalid){
      this.apiService.successModalShow();
    }
  }

  scroll(anchor: string): void {
    this.vps.scrollToAnchor(anchor)
    console.log('Anchor',anchor)
  }
}

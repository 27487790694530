import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  @Input() message:any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  dismiss(){
    this.modalService.dismissAll()
  }
}

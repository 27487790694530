<footer class="footer" style="background-image: url(../../assets/images/footer-bg.png);">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-lg-6">
                <div class="row no-gutters">
                    <div class="col-md-5">
                        <div class="footer-box">
                            <h4>About</h4>
                            <ul>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="footer-box footer-border">
                            <h4>Quick Links</h4>
                            <ul>
                                <li><a href="#">Commercial Scale Projects</a></li>
                                <li><a href="#">Residential Scale Projects</a></li>
                                <li><a href="#">Utility Scale Projects</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="footer-box">
                    <h4>Contact</h4>
                    <ul>
                        <li><a href="mailto:info@provoltus.com">info@provoltus.com</a></li>
                    </ul>
                </div>
                <div class="social-icon">
                    <ul>
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-tumblr"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { categories } from '../config';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  subcategory:boolean[]=[];
  // public category:any;
  public category: any=[];
  constructor( private apiService:  ApiService,private router:Router) { }

  

  ngOnInit(): void {

    this.apiService.Get(categories).then((response:any)=>{
      console.log(response);
      this.category=response['data'];
      console.log(this.category);
    }).catch(error=>{
      console.log(error);
    })

  }
  
  navigateToPartner(id: any, type:any){
    this.router.navigate(['/partner'],{queryParams:{id:id, type:type}})
  }
  header = false;
  @HostListener("window:scroll")
  scrollfunction(){
    if(document.body.scrollTop > 140 || document.documentElement.scrollTop > 140){
      this.header = true;
    }
    else{
      this.header = false;
    }
  }

  mobileNav:boolean = true;

  mobileNavtrigger(){
    this.mobileNav = !this.mobileNav;
  }

}

import { Component, OnInit } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, { Mousewheel, Pagination } from "swiper/core";
SwiperCore.use([Mousewheel, Pagination]);
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '">'+ '0' + (index + 1) + "</span>";
    },
  };
  constructor() { }

  ngOnInit(): void {
    this.onActivate();
  }
  onActivate() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      console.log('pos',pos)
      if (pos > 20) {
        window.scrollTo(20, 0); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
  }
}

<div class="section-pad partner-section" id="partner">
    <div class="container-fluid p-0">
        <div class="row no-gutters align-items-center">
            <div class="col-lg-6">
                <div class="partner-content">
                    <div class="section-header">
                        <h5>Products</h5>
                        <h3><span>Partner Brands</span></h3>
                    </div>
                    <p>Provoltus partners with top global brands to make sure only the best products are installed for the projects. We offer a product range that is technologically advance, more cost-effective, and reliable. From Photovoltaic modules to Inverters, our product range includes a wide variety of solutions for turnkey Solar Project development.</p>
                    <button class="btn btn-md" (click)="scroll('brands')"><span>Explore Brands</span></button>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-left-img">
                    <img src="../../assets/images/home-img/about-left.png" alt="">
                    <div class="left-min-img">
                        <img src="../../assets/images/home-img/min-img.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="logo-slider">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <swiper
                    [slidesPerView]="4"
                    [spaceBetween]="0"
                    [navigation]="true"
                    [breakpoints]="{
                        '320': {
                          slidesPerView: 2
                        },
                        '768': {
                          slidesPerView: 3
                        },
                        '1024': {
                          slidesPerView: 4
                        }
                      }"
                    >
                    <ng-template swiperSlide *ngFor="let spons of sponsers">
                        <a href="{{spons.url}}" target="_blank">
                            <div class="partner-img">
                                <img src="{{imgUrl+spons.display.file_path+spons.display.file_name}}" alt="">
                                <h4>{{spons.name}}</h4>
                            </div>
                        </a>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </div>
</div> -->
<div class="section-pad" id="brands" #brands>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="partner-details">
                    <ng-container *ngIf="catdetails">
                        <a href="{{catdetails.url}}" target="_blank">
                            <img  src="{{imgUrl+catdetails.cover_pic.file_path+catdetails.cover_pic.file_name}}" alt="">
                        </a>
                        <div [innerHTML]="catdetails.description"></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="products" id="products" #products >
    <div class="container">
        <div class="section-header text-center">
            <h3><span>Products</span></h3>
        </div>
        <div class="row">
            <div class="col-lg-6" *ngFor = "let p of filterProducts; let i = index">
                <div class="product-box">
                    <div class="product-banner">
                        <div class="product-banner-image"><img src="{{imgUrl+p.cover_pic.file_path+p.cover_pic.file_name}}" alt="">
                        </div>
                        <div class="product-img">
                            <img src="{{imgUrl+p.display_pic.file_path+p.display_pic.file_name}}" alt="">
                        </div>
                    </div>
                    <div class="product-content">
                        <h4>{{p.name}}</h4>
                        <h5 *ngIf="p.wattage!=''" ><i class="fa fa-bolt"></i> {{p.wattage}}</h5>
                        <p>{{p.description}}</p>
                        <button class="btn btn-enquire" (click)="open(enquire,p)">Enquire</button>
                        <a target="_blank" download href="{{imgUrl+p.datasheet.file_path+p.datasheet.file_name}}" class="btn btn-enquire-outline">Download Datasheet</a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="product-box">
                    <div class="product-banner">
                        <img src="../../assets/images/product-banner.png" alt="">
                    </div>
                    <div class="product-content">
                        <h4>Hiku Mono</h4>
                        <h5><i class="fa fa-bolt"></i> 430 to 455 Wp</h5>
                        <p>HiKu is the first MONO module exceeding 430 W and thus reaches one of the highest poly module power outputs in the solar industry. Combining the best technology and design, This Canadian Solar Panel also reduces LCOE and installation costs.</p>
                        <button class="btn btn-enquire" (click)="open(enquire)">Enquire</button>
                        <button class="btn btn-enquire-outline">Download Datasheet</button>
                    </div>
                </div>
            </div> -->
        </div>
        <div *ngIf ="!filterProducts || filterProducts.length == 0" class="empty-product">
            <h4>No Products Available</h4>
        </div>
    </div>
</div>
<ng-template #enquire let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Product Enquiry</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="bx bx-x"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="validations_form" (ngSubmit)="product(validations_form.value)">
        <div class="row">
            <div class="col-lg-6">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Name <span class="red">*</span></mat-label>
                    <input matInput type="text" formControlName="name">
                </mat-form-field>
                <div class="validation-errors">
                    <ng-container *ngFor="let validation of validation_messages.name">
                        <div
                          class="error-message"
                          *ngIf="validations_form.get('name')!.hasError(validation.type) && (validations_form.get('name')!.dirty || validations_form.get('name')!.touched)"
                        >
                          {{ validation.message }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-6">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Contact Number </mat-label>
                    <input matInput type="text" formControlName="contact_number">
                </mat-form-field>
                <div class="validation-errors">
                    <ng-container *ngFor="let validation of validation_messages.contact_number">
                        <div
                          class="error-message"
                          *ngIf="validations_form.get('contact_number')!.hasError(validation.type) && (validations_form.get('contact_number')!.dirty || validations_form.get('contact_number')!.touched)"
                        >
                          {{ validation.message }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-6">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Email <span class="red">*</span></mat-label>
                    <input matInput type="text" formControlName="email">
                </mat-form-field>
                <div class="validation-errors">
                    <ng-container *ngFor="let validation of validation_messages.email">
                        <div
                          class="error-message"
                          *ngIf="validations_form.get('email')!.hasError(validation.type) && (validations_form.get('email')!.dirty || validations_form.get('email')!.touched)"
                        >
                          {{ validation.message }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-6">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>City </mat-label>
                    <input matInput type="text" formControlName="city">
                </mat-form-field>
                <div class="validation-errors">
                    <ng-container *ngFor="let validation of validation_messages.city">
                        <div
                          class="error-message"
                          *ngIf="validations_form.get('city')!.hasError(validation.type) && (validations_form.get('city')!.dirty || validations_form.get('city')!.touched)"
                        >
                          {{ validation.message }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-6">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Organisation </mat-label>
                    <input matInput type="text" formControlName="organisation">
                </mat-form-field>
                <div class="validation-errors">
                    <ng-container *ngFor="let validation of validation_messages.organisation">
                        <div
                          class="error-message"
                          *ngIf="validations_form.get('organisation')!.hasError(validation.type) && (validations_form.get('organisation')!.dirty || validations_form.get('organisation')!.touched)"
                        >
                          {{ validation.message }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-6">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Product Name </mat-label>
                    <input matInput type="text" value="{{selectedForm.name}}" >
                </mat-form-field>
                <div class="validation-errors">
                    <ng-container *ngFor="let validation of validation_messages.product_id">
                        <div
                          class="error-message"
                          *ngIf="validations_form.get('product_id')!.hasError(validation.type) && (validations_form.get('product_id')!.dirty || validations_form.get('product_id')!.touched)"
                        >
                          {{ validation.message }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-12">
                <mat-form-field appearance="outline" class="form-field form-shadow">
                    <mat-label>Requirement</mat-label>
                    <textarea matInput name="" id="" rows="5" formControlName="requirement"></textarea>
                </mat-form-field>
                <div class="validation-errors">
                    <ng-container *ngFor="let validation of validation_messages.requirement">
                        <div
                          class="error-message"
                          *ngIf="validations_form.get('requirement')!.hasError(validation.type) && (validations_form.get('requirement')!.dirty || validations_form.get('requirement')!.touched)"
                        >
                          {{ validation.message }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="text-center">
                    <button class="btn btn-md" type="submit" (click)="success()"><span> Enquire</span></button>
                </div>
            </div>
        </div>
      </form>
    </div>
</ng-template>

import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
// import {
//   ModalController,
//   NavController,
//   PopoverController,
// } from '@angular/core';
import { LightboxComponent } from '../lightbox/lightbox.component';
import { Galleries } from '../config';

@Component({
  selector: 'app-key-projects',
  templateUrl: './key-projects.component.html',
  styleUrls: ['./key-projects.component.scss']
})
export class KeyProjectsComponent implements OnInit {
  gallery: any;
  imgUrl:any;
  rows:any;

  constructor(private apiService:ApiService,){

  }
  ngOnInit(): void {
    this.apiService.Get(Galleries).then((response:any)=>{
      console.log(response);
      this.imgUrl=response['base_url'];
      this.gallery=response['data'].data;
      this.rows = new Array(Math.ceil(this.gallery.length/2)); 
      
      // for(let i=0;i<this.gallery.length;i++)
      // {
         
      // }
      console.log('rows', this.rows);
      console.log(this.gallery);
    }).catch(error=>{
      console.log(error);
    })
    this.onActivate();
  }
  open(a: any,b: any,c:any){
    console.log(a)
    console.log(b)
    this.apiService.advertiserModalShow(a, b,c);
  }
  onActivate() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      console.log('pos',pos)
      if (pos > 20) {
        window.scrollTo(20, 0); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
  }
}

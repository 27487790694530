import { Component, OnInit } from '@angular/core';
import SwiperCore, {Navigation,Pagination,Autoplay} from 'swiper/core';
import { ApiService } from '../api.service';
import { Galleries } from '../config';
import { Router } from '@angular/router';
SwiperCore.use([Navigation, Pagination, Autoplay]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  active = 1;
  gallery: any;
  imgUrl:any;
  rows:any;
  constructor(private apiService:ApiService, private router:Router) { }

  ngOnInit(): void {
    this.apiService.Get(Galleries).then((response:any)=>{
      console.log(response);
      this.imgUrl=response['base_url'];
      this.gallery=response['data'].data;
      this.rows = new Array(Math.ceil(this.gallery.length/2)); 
      
      // for(let i=0;i<this.gallery.length;i++)
      // {
         
      // }
      console.log('rows', this.rows);
      console.log(this.gallery);
    }).catch(error=>{
      console.log(error);
    })
    this.onActivate();
  }
  onActivate() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      console.log('pos',pos)
      if (pos > 20) {
        window.scrollTo(20, 0); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
  }
  navigateToPartner(id: any, type:any){
    this.router.navigate(['/partner'],{queryParams:{id:id, type:type}})
  }
}

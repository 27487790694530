<button type="button" class="close btn" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
</button>
<a [hidden]='index==0' class="nav-btn prev" (click)='inc(-1)'><i class="bx bx-chevron-left" aria-hidden="true"></i></a>
<div  class="lightbox-content">
    <p>{{index+1}}/{{aray.length}}</p>
    <ng-container *ngIf='imgUrl&&aray[index].file.file_path&&aray[index].file.file_name'>
        <img [src]="imgUrl+aray[index].file.file_path+aray[index].file.file_name" alt="">
    </ng-container>
    <ng-container *ngIf='aray[index].heading'>
        <h3>{{aray[index].heading}}</h3>
    </ng-container>
</div>
<a [hidden]='index==aray.length-1' class="nav-btn next" (click)='inc(+1)'><i class="bx bx-chevron-right" aria-hidden="true"></i>
</a>

<div class="banner-section">
    <!-- <div class="banner-img">
        <img src="../../assets/images/banner-img.png" alt="">
    </div> -->
    <div class="container">
        <div class="row no-gutters align-items-center">
            <div class="col-lg-7">
                <div class="banner-content">
                    <h2>Experience the Future <br><span>of Sustainable Energy</span></h2>
                    <p>Driving the Green Energy Revolution with Top-of-the-Line Services in Solar Project Development, Land Acquisition, Consultation,  Bid Process Management and Solar Product Distribution. A quality partner in the development of Utility Scale Solar for IPPs, Rooftop EPC, Open Access Solar for C&I customers with proven experience and expertise </p>
                    <button class="btn btn-md" routerLink="/contact"><span>Contact Us</span></button><button class="btn btn-outline" (click)="navigateToPartner(1, 'products')">Explore Products</button>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="banner-img">
                    <img src="./assets/images/home-banner-img/main.png" alt="">
                    <div class="banner-mini">
                        <img class="mini-img1" src="./assets/images/home-banner-img/mini-01.png" alt="">
                        <img class="mini-img2" src="./assets/images/home-banner-img/mini-02.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about-content section-pad">
    <img class="energy-img" src="../../assets/images/about-bg.svg" alt="">
    <div class="container-fluid p-0">
        <div class="row no-gutters align-items-center">
            <div class="col-lg-6">
                <div class="about-left-img">
                    <img src="../../assets/images/home-img/about-left.png" alt="">
                    <div class="left-min-img">
                        <img src="../../assets/images/home-img/min-img.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-home-content">
                    <div class="section-header">
                        <h5>Who We Are</h5>
                        <h3><span>About Us</span></h3>
                    </div>
                    <p>Provoltus Energy Services has been a pioneer in delivering turnkey solutions for Renewable Energy Projects Land Acquisition, Consultation, Bid Process Management and Solar Product Distribution. A dynamic team of experts and experienced professionals, Provoltus enables its clients to take a step towards Carbon Positivity by harnessing Earth's Renewable resources for maximum energy extraction. Provoltus was found in 2016 and now is one of the fastest-growing Project Development company with projects of more than 500 MWp under pipeline. We work with IPP’s, Commercial, Industrial and Residential customers to make a move towards a Greener, Sustainable Future. </p>
                    <button class="btn btn-md" routerLink="/about"><span>Know More</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="quote-section" style="background-image: url(../../assets/images/quote-bg.png);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="quote-content">
                    <span class="left-qoute"><img src="../../assets/images/left-quote.png" alt=""></span>
                    <span class="right-qoute"><img src="../../assets/images/right-quote.png" alt=""></span>
                    <h4>Our Mission is to deliver sustainable energy projects with high-performance guarantees to our clients which meet the exact requirements.</h4>
                    <p>To go beyond Solar and make Clean energy all accessible and sustainable</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-pad service-section">
    <img class="back-img" src="../../assets/images/Energy-img.png" alt="">
    <div class="container">
        <div class="section-header text-center">
            <h5>What we do</h5>
            <h3><span>Services We Offer</span></h3>
            <p>We offer services like Renewable Energy Project Development, Consultation, <br>Project & Bid Process Management along with Solar Product Distribution </p>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="service-box">
                    <h5><span class="icon-01"><img src="../../assets/images/service-icon/1.svg" alt=""></span><span class="icon-02"><img src="../../assets/images/service-icon/1c.svg" alt=""></span> Project Development Services</h5>
                    <p>Provoltus provides projects development services in terms of Land Acquisition, Evacuation Approval and other project related approvals for Utility Scale Projects.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="service-box">
                    <h5><span class="icon-01"><img src="../../assets/images/service-icon/2.svg" alt=""></span><span class="icon-02"><img src="../../assets/images/service-icon/2c.svg" alt=""></span> Planning & Consulting Services</h5>
                    <p>Provoltus provides consulting services on Bid Process Management, Design & Engineering and Project Management for Utility Scale Solar Projects.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="service-box">
                    <h5><span class="icon-01"><img src="../../assets/images/service-icon/3.svg" alt=""></span><span class="icon-02"><img src="../../assets/images/service-icon/3c.svg" alt=""></span> Rooftop & Open Access Solar</h5>
                    <p>Provoltus help clients from Residential, Industrial and Commercial sectors to utilize Solar Power to its fullest potential.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="service-box">
                    <h5><span class="icon-01"><img src="../../assets/images/service-icon/4.svg" alt=""></span><span class="icon-02"><img src="../../assets/images/service-icon/4c.svg" alt=""></span> Solar Product Distribution</h5>
                    <p>Provoltus partners with high technology product manufactures for distribution of Solar Products in India. </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="project-section">
    <div class="container">
        <div class="section-header">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <h5>Projects</h5>
                    <h3><span>Key Projects</span></h3>
                </div>
                <div class="col-lg-7">
                    <p>We have a portfolio of successful projects which includes both Utility Scale and Rooftop projects across the country for Industrial, Commercial & Residential sectors.</p>
                </div>
            </div>
        </div>
        <div class="row" *ngFor="let gal of rows; let i = index;">
            <ng-container *ngIf="i < 4">
                <div  [ngClass]="{'col-lg-5': i%2 == 0, 'col-lg-7' : i%2 != 0 }">
                    <div class="project-img">
                        <img  src="{{imgUrl+gallery[i*2].file.file_path+gallery[i*2].file.file_name}}" alt="">
                        <h4>{{gallery[i*2].heading}}</h4>
                    </div>
                </div>
                <div *ngIf="gallery.length > i*2+1"   [ngClass]="{'col-lg-5': i%2 != 0, 'col-lg-7' : i%2 == 0 }">
                    <div class="project-img">
                        <img src="{{imgUrl+gallery[i*2+1].file.file_path+gallery[i*2+1].file.file_name}}" alt="">
                        <h4>{{gallery[i*2+1].heading}}</h4>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="section-pad partner-section">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-lg-6">
                <div class="partner-slider">
                    <swiper
                        [slidesPerView]="1"
                        [spaceBetween]="0"
                        [navigation]="true"
                        >
                        <ng-template swiperSlide>
                            <div class="partner-img">
                                <img src="../../assets/images/project-img/1.png" alt="">
                            </div>
                        </ng-template>
                        <ng-template swiperSlide>
                            <div class="partner-img">
                                <img src="../../assets/images/project-img/4.png" alt="">
                            </div>
                        </ng-template>
                    </swiper>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="partner-content">
                    <div class="section-header">
                        <h5>Products</h5>
                        <h3><span>Partner Brands</span></h3>
                    </div>
                    <p>Provoltus partners with top global brands to make sure only the best products are installed for the projects. We offer a product range that is technologically advance, more cost-effective, and reliable. From Photovoltaic modules to Inverters, our product range includes a wide variety of solutions for turnkey Solar Project development.</p>
                    <button class="btn btn-md" (click)="navigateToPartner(1, 'brands')"><span>Explore Brands</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-pad contact-section">
    <div class="map-img">
        <img src="../../assets/images/contact-banner.png" alt="">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="contact-area">
                    <div class="section-header">
                        <h5>Get in touch</h5>
                        <h3><span>Contact us</span></h3>
                        <p>Want to know more about us? Give us a call or drop <br> us a message</p>
                    </div>
                    <a href="mailto:info@provoltus.com" class="email-id">info@provoltus.com</a>
                    <p>Provoltus Energy Services<br> Corporate Edge <br> Level 6, Wing B <br> Two Horizon Centre<br> Golf Course Road, DLF Phase V <br> Sector 43, Gurugram – 122009</p>
                    <button class="btn btn-md" routerLink="/contact"><span>Contact Us</span></button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-pad">
    <div class="container">
        <div class="section-header">
            <h3><span>Contact Us</span></h3>
        </div>
        <div class="row no-gutters">
            <div class="col-lg-8">
                <div class="contact-form">
                    <p>We would love to hear from you, please fill in the short form below with your questions, suggestions, feedback, inquiries or just about anything and our representatives will get back to you soon.</p>
                    <form [formGroup]="validations_form" (ngSubmit)="enquire(validations_form.value)">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="form-field">
                                    <mat-label>Name <span class="red">*</span></mat-label>
                                    <input matInput type="text" formControlName="name">
                                </mat-form-field>
                                <div class="validation-errors">
                                    <ng-container *ngFor="let validation of validation_messages.name">
                                        <div
                                          class="error-message"
                                          *ngIf="validations_form.get('name')!.hasError(validation.type) && (validations_form.get('name')!.dirty || validations_form.get('name')!.touched)"
                                        >
                                          {{ validation.message }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="form-field">
                                    <mat-label>Contact Number </mat-label>
                                    <input matInput type="text" formControlName="contact_number">
                                </mat-form-field>
                                <div class="validation-errors">
                                    <ng-container *ngFor="let validation of validation_messages.contact_number">
                                        <div
                                          class="error-message"
                                          *ngIf="validations_form.get('contact_number')!.hasError(validation.type) && (validations_form.get('contact_number')!.dirty || validations_form.get('contact_number')!.touched)"
                                        >
                                          {{ validation.message }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="form-field">
                                    <mat-label>Email <span class="red">*</span></mat-label>
                                    <input matInput type="text" formControlName="email">
                                </mat-form-field>
                                <div class="validation-errors">
                                    <ng-container *ngFor="let validation of validation_messages.email">
                                        <div
                                          class="error-message"
                                          *ngIf="validations_form.get('email')!.hasError(validation.type) && (validations_form.get('email')!.dirty || validations_form.get('email')!.touched)"
                                        >
                                          {{ validation.message }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="form-field">
                                    <mat-label>City </mat-label>
                                    <input matInput type="text" formControlName="city">
                                </mat-form-field>
                                <div class="validation-errors">
                                    <ng-container *ngFor="let validation of validation_messages.city">
                                        <div
                                          class="error-message"
                                          *ngIf="validations_form.get('city')!.hasError(validation.type) && (validations_form.get('city')!.dirty || validations_form.get('city')!.touched)"
                                        >
                                          {{ validation.message }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field appearance="outline" class="form-field form-shadow">
                                    <mat-label>Organisation </mat-label>
                                    <input matInput type="text" formControlName="organisation">
                                </mat-form-field>
                                <div class="validation-errors">
                                    <ng-container *ngFor="let validation of validation_messages.organisation">
                                        <div
                                          class="error-message"
                                          *ngIf="validations_form.get('organisation')!.hasError(validation.type) && (validations_form.get('organisation')!.dirty || validations_form.get('organisation')!.touched)"
                                        >
                                          {{ validation.message }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field appearance="outline" class="form-field form-shadow mb-0">
                                    <mat-label>Requirement</mat-label>
                                    <textarea matInput name="" id="" rows="5" formControlName="requirement"></textarea>
                                </mat-form-field>
                                <div class="validation-errors">
                                    <ng-container *ngFor="let validation of validation_messages.requirement">
                                        <div
                                          class="error-message"
                                          *ngIf="validations_form.get('requirement')!.hasError(validation.type) && (validations_form.get('requirement')!.dirty || validations_form.get('requirement')!.touched)"
                                        >
                                          {{ validation.message }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="contact-btn">
                            <button class="btn btn-md" type="submit" (click)="open(success)"><span>Enquire</span></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="right-address">
                    <h4>Address</h4>
                    <p>Provoltus Energy Services<br> Corporate Edge <br> Level 6, Wing B <br> Two Horizon Centre<br> Golf Course Road, DLF Phase V <br> Sector 43, Gurugram – 122009</p>
                    <div class="map-btn">
                        <a href="https://goo.gl/maps/5u4KtKexBPoVsKV69" target="_blank">Google Maps <i class="bx bx-chevron-right"></i></a>
                    </div>
                    <h4>Get in Touch</h4>
                    <a href="tel:+91 9873122743">+91 9873122743</a>
                    <a href="mailto:info@provoltus.com">info@provoltus.com</a>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #success let-modal >
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="bx bx-x"></i></span>
    </button>
    <div class="modal-body">
      <div class="success-message">
        <span><i class="bx bx-check"></i></span>
        <h4>Send Successfully</h4>
      </div>
    </div>
</ng-template>
import { Component } from '@angular/core';
import { NavigationEnd, Router, Event} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pro-voltus';
  constructor(private _router: Router){
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationEnd) {
        // this.onActivate();
        // console.log('route changed');
      }
    });
  }
  // onActivate() {
  //   let scrollToTop = window.setInterval(() => {
  //     let pos = window.pageYOffset;
  //     console.log('pos',pos)
  //     if (pos > 20) {
  //       window.scrollTo(20, pos - 40); // how far to scroll on each step
  //     } else {
  //       window.clearInterval(scrollToTop);
  //     }
  //   }, 2);
  // }
}

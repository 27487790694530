<div class="service-section">
    <div class="container-fluid p-0">
        <div class="row justify-content-end no-gutters">
            <div class="col-lg-6">
                <div class="section-header">
                    <h5>How we work</h5>
                    <h3><span>Services</span></h3>
                </div>
            </div>
        </div>
        <div class="service-content">
            <swiper
                [direction]="'vertical'"
                [slidesPerView]="1"
                [spaceBetween]="30"
                [mousewheel]="true"
                [pagination]="pagination"
                [autoHeight]="true"
                class="mySwiper"
                >
                <ng-template swiperSlide>
                    <div class="swiper-content">
                        <div class="row no-gutters">
                            <div class="col-lg-6">
                                <div class="service-img">
                                    <img src="../../assets/images/service-img/service-big.png" alt="">
                                    <div class="service-mini-img">
                                        <img src="../../assets/images/service-img/service-mini.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="service-box">
                                    <h4><span class="icon"><img src="../../assets/images/service-icon/2.svg" alt=""></span><span class="txt">Project Development Services </span> </h4>
                                    <!-- <h5>Step 1: Talk to a solar expert</h5> -->
                                    <p>Provoltus provides project development services in terms of Land Acquisition, Evacuation Approval and other project related approvals for Utility Scale Projects. We successfully provides resource rich Project sites along with availability of suitable interconnection arrangements for Utility Scale projects. We also provides Civil & other Construction related services for Utility Scale Solar Projects.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template swiperSlide>
                    <div class="swiper-content">
                        <div class="row no-gutters">
                            <div class="col-lg-6">
                                <div class="service-img">
                                    <img src="../../assets/images/service-img/service-big.png" alt="">
                                    <div class="service-mini-img">
                                        <img src="../../assets/images/service-img/service-mini.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="service-box">
                                    <h4><span class="icon"><img src="../../assets/images/service-icon/2.svg" alt=""></span><span class="txt">Planning & Consulting Services</span> </h4>
                                    <!-- <h5>Step 1: Talk to a solar expert</h5> -->
                                    <p>Provoltus provides consulting services on Bid Process Management, Design & Engineering and Project management for Utility Scale Solar Projects. We have successfully managed bids for more than 500 MWp for new entrants in Indian Market. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template swiperSlide>
                    <div class="swiper-content">
                        <div class="row no-gutters">
                            <div class="col-lg-6">
                                <div class="service-img">
                                    <img src="../../assets/images/service-img/service-big.png" alt="">
                                    <div class="service-mini-img">
                                        <img src="../../assets/images/service-img/service-mini.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="service-box">
                                    <h4><span class="icon"><img src="../../assets/images/service-icon/2.svg" alt=""></span><span class="txt">Rooftop & Open Access Solar </span> </h4>
                                    <!-- <h5>Step 1: Talk to a solar expert</h5> -->
                                    <p>Provoltus help clients from Residential, Industrial, Commercial sectors to utilize Solar Power to its fullest potential. Provoltus provides rooftop solar power plants on a CAPEX & OPEX basis and also provides Solar Power through Open Access to Industrial consumers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template swiperSlide>
                    <div class="swiper-content">
                        <div class="row no-gutters">
                            <div class="col-lg-6">
                                <div class="service-img">
                                    <img src="../../assets/images/service-img/service-big.png" alt="">
                                    <div class="service-mini-img">
                                        <img src="../../assets/images/service-img/service-mini.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="service-box">
                                    <h4><span class="icon"><img src="../../assets/images/service-icon/2.svg" alt=""></span><span class="txt">Solar Product Distribution </span> </h4>
                                    <!-- <h5>Step 1: Talk to a solar expert</h5> -->
                                    <p>Provoltus partners with high technology product manufactures for distribution of Solar Products in India. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </div>
</div>

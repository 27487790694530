import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnInit {
  @Input() index: number = 0;
  @Input() aray:any;
 @Input() imgUrl:any;
  constructor(private modalService: NgbModal) {
    
   }

  ngOnInit(): void {
    console.log(this.index);
    console.log(this.aray);
  }

  inc(value:number){
    this.index += value;
    console.log(this.index);
  }
  dismiss(){
  this.modalService.dismissAll()
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient } from '@angular/common/http';
import { baseUrl, contactInfo } from '../config';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  closeResult = '';
  validations_form: FormGroup;
  presentToast: any;

  validation_messages = {
    name: [
      {
        type: 'required',
        message: 'Name is a required field.',
      },
    ],
    contact_number: [
      {
        type: 'required',
        message: 'Mobile number is required.',
      },
      {
        type: 'minLength',
        message: 'Invalid mobile number.',
      },
      {
        type: 'maxLength',
        message: 'Invalid mobile number.',
      }
    ],
    email: [
      {
        type: 'required',
        message: 'Email is a required field.',
      },
    ],
    city: [
      {
        type: 'required',
        message: 'city is a required field.',
      },
    ],
    organisation: [
      {
        type: 'required',
        message: 'organisation is a required field.',
      },
    ],
    requirement: [
      {
        type: 'required',
        message: 'requirement is a required field.',
      },
    ],
  };

  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private httpclient:HttpClient, private apiService:  ApiService) { 


    this.validations_form = this.formBuilder.group({
      name: new FormControl('', Validators.compose([ 
        Validators.required,
      ])),
      contact_number: new FormControl('', Validators.compose([ 
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      city: new FormControl('', Validators.compose([ 
        Validators.required,
      ])),
      organisation: new FormControl('', Validators.compose([
        Validators.required,
      ])),
      requirement: new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });
  }

  ngOnInit(): void {
    this.onActivate();
  }

  enquire(value :any){
    if (!this.validations_form.invalid) {
      this.apiService.Post(contactInfo,value).then(data=>{
        this.validations_form.reset();
      }).catch((error)=>{

        console.log(error);
  
      });
    }
    else{
      console.log("invalid condition");
    }
    
  }
  open(success: any) {
    if (!this.validations_form.invalid) {
      this.modalService.open(success, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onActivate() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      console.log('pos',pos)
      if (pos > 20) {
        window.scrollTo(20, 0); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 2);
  }
}
